@import "~tailwindcss/base";
@import "~tailwindcss/components";
@import "~tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");

/* ONEST */
@font-face {
  font-family: "onest-regular";
  src: url("./asset/font/Onest/TTF/OnestRegular1602-hint.ttf");
  font-display: swap;
}

@font-face {
  font-family: "onest-bold";
  src: url("./asset/font/Onest/TTF/OnestBold1602-hint.ttf");
  font-display: swap;
}

@font-face {
  font-family: "onest-medium";
  src: url("./asset/font/Onest/TTF/OnestMedium1602-hint.ttf");
  font-display: swap;
}

@font-face {
  font-family: "onest-black";
  src: url("./asset/font/Onest/TTF/OnestBlack1602-hint.ttf");
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif !important;
}

body {
  width: 100%;
  height: 100vh;
  /* background-color: "#fff"; */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
::-webkit-scrollbar {
  display: none;
}

.input {
  background-color: transparent !important;
}

.pagination {
  display: flex;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;
}
.pagination a {
  padding: 10px;
  border: 1px solid transparent;
  color: #505050;
}
.paginationLink {
  font-weight: bold;
}

.paginationActive a {
  color: #505050;
  border: 1px solid #1e5c2e;
  border-radius: 80%;
  background: linear-gradient(to bottom, #fff 0, #eaeaea 100%);
}

.paginationDisabled a {
  color: #cacaca;
}

@media print {
  @page { margin: 0; }
  body { margin: 1.6cm; }
}

.off-screen {
  position: absolute;
  left: -9999px;
  top: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

/* Show error when duplicate is scanned */
/* Show prompt when user cancels */